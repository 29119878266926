import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';

import CloseIcon from '/src/images/icons/close.svg';

const VideoModal = ({ onClose, showModal, video }) => {
  if (!video) return null;

  // I extract the embed URL from the iframe returned by Prismic
  // because the iframe returns fixed dimensions and I want a bit more customization
  const extractEmbedURL = (prismicEmbedVideoHTML) => {
    // let's solve this with good old regular expressions:
    // We look for src="XXX", where XXX (videosrc) is what we need:
    const regexp = /src="(?<videosrc>[^"]+)"/
    const match = regexp.exec(prismicEmbedVideoHTML)
    return match.groups.videosrc
  }

  const videoUrl = extractEmbedURL(video.html);

  return (
    <Modal
      centered
      contentClassName="relative bg-transparent rounded-md border-0"
      dialogClassName="container w-modal max-h-modal"
      scrollable
      show={showModal}
      onHide={onClose}
    >
      <Modal.Header className="absolute w-full z-10 flex justify-end border-0 p-3">
        <div
          className="bg-white rounded-md p-2"
          role="button"
          tabIndex={0}
          onKeyDown={onClose}
          onClick={onClose}
        >
          <CloseIcon className="flex text-black" />
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="embedded-video">
          <iframe
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            height="500"
            src={videoUrl}
            width="100%"
          />
        </div>
      </Modal.Body>
    </Modal>
  )
};

VideoModal.propTypes = {
  onClose: PropTypes.func,
  showModal: PropTypes.bool,
  video: PropTypes.shape({
    embed_url: PropTypes.string,
    html: PropTypes.string,
    title: PropTypes.string
  })
};

VideoModal.defaultProps = {
  onClose: () => {},
  showModal: false,
  video: null
}

export default VideoModal;
