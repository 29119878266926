import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Teaser from "../components/teaser"
import Cover from "../components/cover"
import Newsletter from "../components/newsletter"
import IconPaper from "../../static/icons/paper.svg";
import VideoModal from "../components/VideoModal";

const NewsPage = ({data}) => {
  const [currentVideo, setCurrentVideo] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleModalClose = () => {
    setShowVideoModal(false);
  };

  const handleNewsCardClick = (event, item) => {
    event.preventDefault();

    setCurrentVideo(item);
    setShowVideoModal(true);
  };

  const handleNewsCardKeyDown = (event, item) => {
    if (event.keyCode === 32 || event.keyCode === 13) {
      event.preventDefault();

      setCurrentVideo(item);
      setShowVideoModal(true);
    }
  };

  const news = data.allPrismicNews.edges;

  // const cover = news.slice(0,1).map((edge) => {
  //   console.log(edge.node.data.hero_image)
  //   return(
  //     <Link to={`/news/${edge.node.uid}`} >
  //       <Cover 
  //         title={edge.node.data.title.text} 
  //         text={edge.node.data.teasertext.text} 
  //         date={edge.node.first_publication_date}
  //         image={edge.node.data.hero_image}
  //       />
  //     </Link>
  //   )
  // });

  const teaser = news.slice(0,99).map((edge) => {
    return (
      <Link
        key={edge.node.uid}
        to={`/news/${edge.node.uid}`}
      >
        <Teaser
          title={edge.node.data.title.text} 
          text={edge.node.data.teasertext.text} 
          image={edge.node.data.hero_image}
          date={edge.node.first_publication_date}
          onlyVideo={edge.node.data.only_video}
          onOpenVideoButtonClick={event => handleNewsCardClick(event, edge.node.data.video)}
          onOpenVideoButtonKeyDown={event => handleNewsCardKeyDown(event, edge.node.data.video)}
        />
      </Link>
    )
  });

  return (
    <>
      <Layout>
        <Seo title="News" description="The latest and official news of the ecoqube project." />
        <div className="max-w-screen-xl mx-auto px-4 relative z-20 mt-20">
          <div className="max-w-screen-md mx-auto text-center">
            <div className="inline-flex justify-center items-center">
              <div className="relative inline-block mr-6">
                <img src={'../cube-skelleton.svg'} className="w-16" />
                <IconPaper className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-1" />
              </div>
              <h2 className="text-4xl lg:text-5xl font-bold font-display text-center">Official News</h2>
            </div>
          </div>
          <div className="grid md:grid-cols-3 gap-8 lg:gap-12 mt-12">
            {/* <div className="md:col-span-3">
              {cover}
            </div> */}
            {teaser}
          </div>
        </div>
        <div className="max-w-screen-sm mx-auto px-4 mt-20 lg:mt-40 relative">
          <Newsletter />
        </div>
      </Layout>
      <VideoModal
        video={currentVideo}
        showModal={showVideoModal}
        onClose={handleModalClose}
      />
    </>
  )
}

NewsPage.propTypes = {
  data: PropTypes.shape({
    allPrismicNews: PropTypes.object.isRequired,
  }).isRequired,
}

export const query = graphql`
  query News {
    allPrismicNews(sort: {fields: first_publication_date, order: DESC}) {
      edges {
        node {
          uid
          first_publication_date(formatString: "DD.MM.y")
          data {
            hero_image {
              alt
              fluid(maxWidth: 1248) {
                ...GatsbyPrismicImageFluid
              }
            }
            teasertext {
              text
            }
            title {
              text
            }
            only_video
            video {
              embed_url
              html
              title
            }
          }
        }
      }
    }
  }
`

export default NewsPage
