import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import IconChevronRight from "../../static/icons/chevron-right.svg"
import IconPlayCircle from "../../static/icons/play-circle.svg"
import Img from 'gatsby-image'

const Teaser = ({ title, date, text, image, onlyVideo, onOpenVideoButtonClick, onOpenVideoButtonKeyDown }) => (
  <div className="rounded bg-layout-gray-900 flex md:block transition cursor-pointer hover:scale-99 transform">
    <div className="order-2 w-40 md:w-auto flex-shrink-0 p-4 md:p-0">
      <Img fluid={image.fluid} alt={image.alt} className="rounded md:rounded-r-none md:rounded-t" />
    </div>
    <div className="p-6">
      <h3 className="font-bold text-2xl">{title}</h3>
      <p className="text-layout-gray-400 text-base mt-1 font-bold">{date}</p>
      <p className="mt-4">{text}</p>
      <div className="flex flex-col sm:flex-row md:flex-col lg:flex-row items-center justify-between">
        <div className="inline-flex items-center mt-4 text-primary-base">
          <span className="inline-block font-bold">
            Read more
          </span>
          <IconChevronRight className="flex-shrink-0 stroke-current ml-2" />
        </div>
        {!!onlyVideo && (
          <div
            className="inline-flex items-center mt-4 text-primary-base"
            tabIndex={0}
            role="button"
            onClick={onOpenVideoButtonClick}
            onKeyDown={onOpenVideoButtonKeyDown}
          >
            <span className="inline-block font-bold">
              Open video
            </span>
            <IconPlayCircle className="flex-shrink-0 stroke-current ml-2" />
          </div>
        )}
      </div>
    </div>
  </div>
)

Teaser.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
  onlyVideo: PropTypes.bool,
  onOpenVideoButtonClick: PropTypes.func,
  onOpenVideoButtonKeyDown: PropTypes.func
}

Teaser.defaultProps = {
  title: 'Energy Consumption',
  date: '12. April 2021',
  text: '',
  onlyVideo: false,
  onOpenVideoButtonClick: () => {},
  onOpenVideoButtonKeyDown: () => {}
}

export default Teaser
