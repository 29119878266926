import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import IconChevronRight from "../../static/icons/chevron-right.svg"
import Img from 'gatsby-image'

const Cover = ({ title, date, text, image }) => (
  <Link to="" className="rounded bg-layout-gray-900 flex md:block transition cursor-pointer hover:scale-99 transform">
    <div className="order-2 w-40 md:w-auto flex-shrink-0 p-4 md:p-0">
      <Img fluid={image.fluid} alt={image.alt} className="rounded" />
    </div>
    <div className="p-12 bg-gradient-to-r rounded-l from-layout-gray-base to-transparent top-0 left-0 h-full w-full">
      <h3 className="font-bold text-3xl md:text-4xl">{title}</h3>
      <p className="text-layout-gray-400 text-base mt-1 font-bold">{date}</p>
      <p className="mt-4">{text}</p>
      <div className="inline-flex items-center mt-4 text-primary-base">
        <span className="inline-block font-bold">Read more</span>
        <IconChevronRight className="flex-shrink-0 stroke-current ml-2" />
      </div>
    </div>
  </Link>
)

Cover.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
}

Cover.defaultProps = {
  title: 'Energy Consumption',
  date: '12. April 2021',
  text: 'Proposals should investigate innovative design concepts and advanced ICT solutions for integrated design of server rooms and small data centres in buildings (based on state-of-the-art sustainable data centre...'
}

export default Cover
